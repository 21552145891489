<template>
  <!-- 卡密 - 卡密发放列表 - 添加/编辑 -->
  <div class="pageContol listWrap grantListInfo">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">卡密管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">卡密发放列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{
            id ? "追加卡密" : "生成卡密"
          }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          size="small"
          ><el-form-item label="卡名称" prop="cardName">
            <el-input
              v-model="ruleForm.cardName"
              maxlength="20"
              placeholder="请输入卡名称"
              show-word-limit
              :disabled="id ? true : false"
            ></el-input>
          </el-form-item>
          <el-form-item label="卡类型" prop="cardType">
            <el-radio-group
              @change="clickClear"
              v-model="ruleForm.cardType"
              :disabled="id ? true : false"
              ><el-radio :label="1">题库</el-radio>
              <el-radio :label="2">课程</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="题库类别"
            prop="curriculum"
            v-if="ruleForm.cardType == 1"
          >
            <el-cascader
              :disabled="id ? true : false"
              v-model="ruleForm.trainType"
              :options="TreeList"
              :props="propsTree"
              clearable
              filterable
              size="small"
            ></el-cascader>
          </el-form-item>
          <el-form-item
            label="课程"
            prop="trainType"
            v-if="ruleForm.cardType == 2"
          >
            <el-select
              clearable
              multiple
              size="small"
              :disabled="id ? true : false"
              v-model="ruleForm.curriculum"
              filterable
              remote
              :remote-method="getCourse"
              placeholder="请至少输入两个字搜索课程"
            >
              <el-option
                size="small"
                v-for="item in courseList"
                :key="item.courseId"
                :label="item.courseName"
                :value="item.courseId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="id ? '追加数量' : '发放数量'"
            prop="cardNumber"
            class="ccardNumber"
          >
            <el-input
              v-model="ruleForm.cardNumber"
              maxlength="4"
              placeholder="请输入发放数量"
              show-word-limit
              @keyup.native="
                ruleForm.cardNumber = zF.positiveInteger(ruleForm.cardNumber)
              "
            ></el-input>
          </el-form-item>
          <el-form-item
            label="兑换有效期"
            prop="termValidity"
            class="ctermValidity"
            ><el-date-picker
              v-model="ruleForm.termValidity"
              type="datetimerange"
              size="small"
              clearable
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :disabled="id ? true : false"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="所属机构" prop="compId">
            <el-select
              size="small"
              v-model="ruleForm.compId"
              remote
              :remote-method="getCompanyList"
              filterable
              clearable
              :disabled="id ? true : false"
              @change="compIdChange"
              placeholder="请至少输入两个字搜索"
            >
              <el-option
                v-for="item in CompanyList"
                :key="item.compId"
                :label="item.compName"
                :value="item.compId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="cz">
            <el-button
              class="bgc-bv"
              size="small"
              round
              @click="$router.back(-1)"
              >取消</el-button
            >
            <el-button
              class="bgc-bv"
              size="small"
              round
              type="primary"
              @click="submitForm1('ruleForm')"
              >确定</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 点击确定时弹出确认核对信息 -->
    <el-dialog
      class="dialog1"
      title="请仔细核对以下信息是否正确"
      :visible.sync="dialogVisible1"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose"
      ><p class="p1">
        <span class="s1">卡名称：</span>
        <span class="s2">{{ ruleForm.cardName }}</span>
      </p>
      <p class="p1">
        <span class="s1">卡类型：</span>
        <span class="s2">{{ ruleForm.cardType == 1 ? "题库" : "课程" }}</span>
      </p>
      <p class="p1">
        <span class="s1">{{ id ? "追加数量" : "发放数量" }}：</span>
        <span class="s2">{{ ruleForm.cardNumber }}</span>
      </p>
      <p class="p1">
        <span class="s1">有效期：</span>
        <span class="s2"
          >{{ ruleForm.termValidity ? ruleForm.termValidity[0] :'' }} 至
          {{ ruleForm.termValidity ? ruleForm.termValidity[1]:'' }}</span
        >
      </p>
      <p class="p1" v-if="ruleForm.compName || CompanyList.length">
        <span class="s1">所属机构：</span>
        <span class="s2"
          >{{ ruleForm.compName || CompanyList[0].compName }}
        </span>
      </p>
      <p class="p2">
        卡密一旦生成，便不可更改，只能追加数量。追加数量时，请仔细核对兑换有效期是否已过期。
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button class="bgc-bv" size="small" @click="dialogVisible1 = false"
          >取消</el-button
        >
        <el-button
          class="bgc-bv"
          size="small"
          type="primary"
          :loading="loading1"
          @click="submitForm2('ruleForm')"
          >{{ loading1 ? "生成中" : "确定" }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import moment from "moment";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "grantListInfo",
  data() {
    return {
      // 有id的为编辑/无id的为添加
      id: "",
      // 卡密数据
      ruleForm: {
        cardName: "", // 卡名称
        cardType: 1, // 卡类型
        trainType: "", // 卡类型 - 题库 - 培训类型
        curriculumRadio: 20, // 卡类型 - 课程 - 20:课程分类;21:课程方;22:课程
        curriculumType: [], // 卡类型 - 课程 - 课程分类
        curriculumSquare: [], // 卡类型 - 课程 - 课程方
        curriculum: [], // 卡类型 - 课程 - 课程
        cardNumber: "", // 发放数量
        termValidity: [], // 兑换有效期
        compId: "", //机构Id
        compName: "", //机构名称
      },
      // 卡密数据 - 下拉数据
      ruleFormSelectDatas: {
        curriculumType: [], // 卡密数据 - 卡类型 - 课程 - 课程分类 - 下拉数据
        curriculumSquare: [], // 卡密数据 - 卡类型 - 课程 - 课程分类 - 课程方
        curriculum: [], // 卡密数据 - 卡类型 - 课程 - 课程分类 - 课程
      },
      // 卡密数据 - 校验
      rules: {
        // 卡名称
        cardName: [
          { required: true, message: "请输入卡名称", trigger: "blur" },
        ],
        // 卡类型
        cardType: [
          { required: true, message: "请选择卡类型", trigger: "change" },
        ],
        // 发放数量
        cardNumber: [
          { required: true, message: "请输入发放数量", trigger: "blur" },
        ],
        // 兑换有效期
        termValidity: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
      },
      // 卡类型 - 课程 - 关键字查询的loading
      loading: false,
      // 点击确定时弹出确认核对信息 - loading
      dialogVisible1: false,
      // 点击确认生成卡密 - 没有生成成功不让点击
      loading1: false,
      // 题库类别 - 数据
      TreeList: [],
      // 题库类别 - 数据
      propsTree: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      //所属机构数据
      CompanyList: [],
      //精品课数据
      courseList: [],
    };
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {},
  methods: {
    // 获取 - 题库类别 - 下拉数据
    getTree() {
      this.$post(
        "/miniapp/category/enable/tree",
        { disabledLevels: "1,2,3" },
        3000,
        true,
        2
      ).then((ret) => {
        this.TreeList = ret.data;
      });
    },
    // 点击 - 卡类型;课程 - 课程单选的时候,清空之前已选的数据,保持单选数据
    clickClear(item) {
      this.ruleForm.trainType = [];
      this.ruleForm.curriculumType = [];
      this.ruleForm.curriculumSquare = [];
      this.ruleForm.curriculum = [];
    },
    // 密数据 - 卡类型 - 题库 - 培训类型 - 关键字搜索数据
    trainType() {
      this.$post("/rpc/category/qb-train-type/tree", {}, 10000, false, 2)
        .then((res) => {
          this.ruleFormSelectDatas.trainType = res;
        })
        .catch(() => {});
    },
    // 密数据 - 卡类型 - 课程 - 课程分类 - 关键字搜索数据
    curriculumType(query) {
      if (query !== "") {
        this.loading = true;
        this.$post(
          "/biz/ec/course/type/card/key/list",
          {
            name: query,
          },
          3000,
          true,
          2
        )
          .then((res) => {
            this.loading = false;
            this.ruleFormSelectDatas.curriculumType = res.data;
          })
          .catch(() => {});
      } else {
        this.ruleFormSelectDatas.curriculumType = [];
      }
    },
    // 密数据 - 卡类型 - 课程 - 课程方 - 关键字搜索数据
    curriculumSquare(query) {
      if (query !== "") {
        this.loading = true;
        this.$post(
          "/biz/ec/course/owner/list",
          {
            name: query,
          },
          3000,
          true,
          2
        )
          .then((res) => {
            this.loading = false;
            this.ruleFormSelectDatas.curriculumSquare = res.data;
            console.log(res.data);
          })
          .catch(() => {});
      } else {
        this.ruleFormSelectDatas.curriculumSquare = [];
      }
    },
    // 密数据 - 卡类型 - 课程 - 课程 - 关键字搜索数据
    curriculum(query) {
      if (query !== "") {
        this.loading = true;
        this.$post(
          "biz/ec/course/cd/key/list",
          {
            courseName: query,
          },
          3000,
          true,
          2
        )
          .then((res) => {
            this.loading = false;
            this.ruleFormSelectDatas.curriculum = res.data;
          })
          .catch(() => {});
      } else {
        this.ruleFormSelectDatas.curriculum = [];
      }
    },
    // 确定 - 弹出确认信息
    submitForm1(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogVisible1 = true;
        }
      });
    },
    // 确定 - 提交
    submitForm2() {
      let url = "";
      let data = {
        walletName: this.ruleForm.cardName,
        walletType: this.ruleForm.cardType,
        issuedNum: this.ruleForm.cardNumber,
        startDate: this.ruleForm.termValidity[0] || "",
        endDate: this.ruleForm.termValidity[1] || "",
        compId: this.ruleForm.compId,
      };
      if (data.walletType == 1) {
        data.rangeType = 10;
        data.resourceIds = this.ruleForm.trainType.split();
      } else {
        data.rangeType = 22;
        data.resourceIds = this.ruleForm.curriculum;
      }
      if (this.id) {
        data.walletId = this.id;
        url = "/biz/card/wallet/appendNum";
      } else {
        url = "/biz/card/wallet/insert";
      }
      this.loading1 = true;
      this.$post(url, data, 3000, false, 2)
        .then((res) => {
          if (res.status == 0) {
            if (this.id) {
              this.$message.success("追加成功!");
            } else {
              this.$message.success("已成功生成卡密!");
            }

            this.$router.go(-1);
          } else {
            this.$message.warning(res.message);
          }
          this.loading1 = false;
          this.dialogVisible1 = false;
        })
        .catch(() => {
          this.loading1 = false;
          this.dialogVisible1 = false;
        });
    },
    // 取消
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.CompanyList = [res.data];
      });
    },
    //课程搜索
    getCourse(query) {
      if (query.trim().length >= 2) {
        this.$post(
          "minicourse/operation/selectList",
          { courseName: query },
          3000,
          true,
          2
        )
          .then((res) => {
            if (res.status == 0) {
              this.courseList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.courseList = [];
      }
      //
    },
    compIdChange(val) {
      let obj = {};
      obj = this.CompanyList.find((item) => {
        return item.compId == val; //筛选出对应数据
      });
      this.ruleForm.compName = obj.compName;
    },
  },
  computed: {},
  mounted() {},
  created() {
    this.trainType();
    this.getTree();
    // 初始化判断 - 有id的为编辑/无id的为添加
    if (this.$route.query.walletId) {
      this.id = this.$route.query.walletId;
      this.$post(
        "/biz/card/wallet/getInfo",
        { walletId: this.id },
        3000,
        false,
        2
      )
        .then((res) => {
          this.ruleForm.cardName = res.data.walletName;
          this.ruleForm.compId = res.data.compId;
          this.ruleForm.cardType = Number(res.data.walletType);
          if (res.data.walletType == 1 && res.data.categoryCode) {
            this.ruleForm.trainType = res.data.categoryCode;
            // for (let i = 0; i < res.data.trainTypeNames.length; i++) {
            //   this.ruleForm.trainType.push(
            //     res.data.trainTypeNames[i].trainTypeId
            //   );
            // }
          } else {
            if (res.data.miniCourses) {
              this.courseList = res.data.miniCourses;
              for (let i = 0; i < res.data.miniCourses.length; i++) {
                this.ruleForm.curriculum.push(res.data.miniCourses[i].courseId);
              }
              // this.ruleFormSelectDatas.curriculum = res.data.ecCourses;
            }
          }
          this.ruleForm.termValidity = [
            res.data.startDate.replace(/\//g, "-"),
            res.data.endDate.replace(/\//g, "-"),
          ];
          if (res.data.compId) {
            this.getCompany(res.data.compId);
          }
        })
        .catch(() => {});
    }
  },
};
</script>

 <style lang="less" scoped>
.grantListInfo {
  .framePage {
    .framePage-body {
      padding: 60px;
      .el-form {
        width: 700px;
        .ccardNumber {
          width: 300px;
        }
        .ctermValidity {
          width: 500px;
        }
        .cz {
          text-align: center;
          margin-top: 20%;
          .el-button {
            padding: 9px 25px;
          }
        }
      }
    }
  }
  .dialog1 {
    .p1 {
      line-height: 30px;
      padding-left: 20px;
      .s2 {
        color: #909399;
      }
    }
    .p2 {
      color: #f56c6c;
      margin-top: 20px;
      padding: 0 20px;
    }
  }
}
.grantListInfo
  .framePage
  .framePage-body
  .el-form
  .ccurriculum
  /deep/
  .el-form-item__label {
  width: 70px !important;
}
.grantListInfo
  .framePage
  .framePage-body
  .el-form
  .ccurriculum
  /deep/
  .el-form-item__content {
  margin-left: 70px !important;
}
</style>